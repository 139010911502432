import { useEffect, useState } from "react";
import Link from "next/link";

import type { Challenge } from "@/types/challenge";
import Layout from "@/components/common/Layout";
import Markdown from "@/components/common/Markdown";
import ChallengeService from "@/services/client/Challenge";

export default function Index() {
  const [loading, setLoading] = useState(true);
  const [latestChallenge, setLatest] = useState<Challenge>();

  useEffect(() => {
    if (!loading) {
      return;
    }

    async function fetchData() {
      const latestChallenge = await ChallengeService.getLatest();
      if (!latestChallenge) {
        return;
      }
      setLatest(latestChallenge);
      setLoading(false);
    }

    fetchData();
  });

  return (
    <Layout>
      <h1 className="py-8 text-center text-4xl tracking-tighter font-extrabold md:text-5xl">
        Prompt Hacker
      </h1>

      <h2 className="py-2 text-center text-3xl font-bold">
        Prompt Engineering Challenge
        <p className="py-2 text-lg text-neutral-500">
          Write a prompt to finish the given task.
        </p>
      </h2>

      <p className="py-2 text-center text-xl">
        Get started by writing a prompt to pass{" "}
        <Link
          href="/challenges/hello-world"
          className="font-semibold text-sky-600"
        >
          &lt;&lt; Hello World &gt;&gt;
        </Link>
        .
      </p>

      {latestChallenge ? (
        <div className="mt-6 w-full">
          <span className="w-full font-bold text-2xl border-b inline-block py-2">
            Latest Challenge
          </span>

          <div className="mt-4 border rounded-lg p-2 px-4 bg-sky-100">
            <p className="text-xl underline">
              <Link href={`/challenges/${latestChallenge.slug}`}>
                {latestChallenge.seq}. {latestChallenge.title}
              </Link>
            </p>
            <p className="mt-4 text-sm">{latestChallenge.description}</p>
            <p className="mt-4 text-sm">
              <Markdown content={latestChallenge.content} />
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </Layout>
  );
}
